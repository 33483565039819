import { types } from "./../types/types.js";

const initialState = {
	isLoadingCategories: false,
	allCategories: [],
	topCategories: [],
	topPublicationsByCategory: [],
	isClickCategory: false,
};

export const categoriesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.categoriesSetIsLoading:
			return {
				...state,
				isLoadingCategories: action.payload,
			};
		case types.categoriesSetIsClickCategory:
			return {
				...state,
				isClickCategory: action.payload,
			};
		case types.categoriesLoadAll:
			return {
				...state,
				allCategories: [...action.payload],
			};
		case types.categoriesLoadTop:
			return {
				...state,
				topCategories: [...action.payload.categories],
				topPublicationsByCategory: [
					...action.payload.topPublicationsFinal,
				],
			};
		default:
			return state;
	}
};
