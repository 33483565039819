import { types } from "./../types/types.js";


const initialState = {
	isLoadingAccountTypes: false,
	allAccountTypes: [],
};

export const accountTypesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.accountTypesSetIsLoading:
			return { 
				...state,
        		isLoadingAccountTypes: action.payload,
			};
		case types.accountTypesLoadAll:
			return { 
				...state,
				allAccountTypes: [ ...action.payload ],
			};
		default:
			return state;
	}
};