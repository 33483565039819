import { types } from "./../types/types.js";

const initialState = {
    isLoadingPublications: false,
    isLoadingSearch: false,
    startSearch: false,
    reloadPublications: false,
    latestPublications: [],
    publicationsWithPagination: [],
    totalPublications: 0,
    totalSearchPublications: 0,
    publicationById: {},
    userPublications: [],
    userPublicationsWithoutPagination: [],
    searchPublications: [],
    publicationList: localStorage.getItem("publications")
        ? JSON.parse(localStorage.getItem("publications"))
        : [],
    editNumber: localStorage.getItem("publications")
        ? JSON.parse(localStorage.getItem("publications")).length + 1
        : 1,
};

export const publicationsReducer = (state = initialState, action) => {
    let index;
    switch (action.type) {
        case types.publicationsLoadLatest:
            return {
                ...state,
                latestPublications: [...action.payload.rows],
                totalPublications: action.payload.count,
            };
        case types.publicationsLoadWithPagination:
            return {
                ...state,
                publicationsWithPagination: [...action.payload.publications],
                totalPublications: action.payload.totalItems,
            };
        case types.publicationCreate:
            return {
                ...state,
                latestPublications: [
                    action.payload,
                    ...state.latestPublications,
                ],
                publicationsWithPagination: [
                    action.payload,
                    ...state.publicationsWithPagination,
                ],
                totalPublications: state.totalPublications + 1,
            };
        case types.resetPublicationSearch:
            return {
                ...state,
                searchPublications: null,
                totalSearchPublications: null,
            };
        case types.publicationSearch:
            return {
                ...state,
                searchPublications: [
                    ...action.payload.publications.publications,
                ],
                totalSearchPublications: action.payload.publications.totalItems,
            };
        case types.publicationsSetIsLoading:
            return {
                ...state,
                isLoadingPublications: action.payload,
            };
        case types.publicationsSetIsLoadingSearch:
            return {
                ...state,
                isLoadingSearch: action.payload,
            };
        case types.publicationsSetStartSearch:
            return {
                ...state,
                startSearch: action.payload,
            };
        case types.publicationsResetPublicationById:
            return {
                ...state,
                publicationById: {},
            };
        case types.publicationsResetPublicationsList:
            return {
                ...state,
                publicationList: [],
            };
        case types.publicationsResetPublicationsWithPagination:
            return {
                ...state,
                publicationsWithPagination: [],
            };
        case types.publicationsResetPublicationEditNumber:
            return {
                ...state,
                editNumber: 1,
            };
        case types.publicationReloadPublications:
            return {
                ...state,
                reloadPublications: action.payload,
            };
        case types.publicationLoadById:
            return {
                ...state,
                publicationById: action.payload,
            };
        case types.publicationUpdate:
            return {
                ...state,
                publicationById: action.payload,
            };
        case types.publicationDelete:
            return {
                ...state,
                userPublications: state.userPublications.filter(
                    (items) => items.publication_id !== action.payload
                ),
            };
        case types.publicationStatusUpdate:
            index = state.userPublications.findIndex(
                (publication) =>
                    publication.publication_id === action.payload.publication_id
            );
            state.userPublications[index] = action.payload;
            return {
                ...state,
                userPublications: state.userPublications,
            };
        case types.publicationPriceUpdate:
            index = state.userPublications.findIndex(
                (publication) =>
                    publication.publication_id === action.payload.publication_id
            );
            state.userPublications[index] = action.payload;
            return {
                ...state,
                userPublications: state.userPublications,
            };
        case types.publicationAdd:
            const newList = state.publicationList.filter(
                (publication) => publication.publication_id !== state.editNumber
            );

            // const newList1Item = action.payload;

            // if (!newList.length) {
            // 	newList1Item.publication_id = 0;
            // 	newList.push(newList1Item)
            // } else {
            // 	newList1Item.publication_id = state.publicationList.length;
            // 	newList[state.editNumber-1] = newList1Item;
            // }
            return {
                ...state,
                publicationList: [...newList, action.payload],
            };
        case types.addAnother:
            return state.publicationList.length + 1 === action.payload
                ? {
                      ...state,
                      editNumber: action.payload,
                  }
                : {
                      ...state,
                  };
        case types.publicationRemove:
            return {
                ...state,
                publicationList: state.publicationList.filter(
                    (publication) =>
                        publication.publication_id !== action.payload
                ),
                editNumber:
                    state.publicationList.length > 1
                        ? state.publicationList.length - 1
                        : 1,
            };
        case types.publicationUpdatePublicationId:
            return {
                ...state,
                publicationList: state.publicationList.map(
                    (publication, index) => {
                        publication.publication_id = index;
                        return publication;
                    }
                ),
            };
        case types.publicationsUpdateImages:
            return {
                ...state,
                publicationById: action.payload,
            };
        case types.publicationsOfUser:
            return {
                ...state,
                userPublications: [...action.payload.rows],
                totalPublications: action.payload.count,
            };
        case types.resetPublicationsOfUser:
            return {
                ...state,
                userPublications: [],
                totalPublications: 0,
            };
        case types.publicationsOfUserWithoutPagination:
            return {
                ...state,
                userPublicationsWithoutPagination: action.payload,
            };
        default:
            return state;
    }
};
