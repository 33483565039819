import { types } from "./../types/types";

const initialState = {
	isLoadingChilexpress: false,
	allChilexpressRegions: [],
	citiesByRegionIdChilexpress: [],
	branchesByRegionIdAndCityNameChilexpress: [],
	shippingPriceChilexpress: 0,
	serviceDeliveryCode: "",
	ticketChilexpress: {},
	trackingInfoChilexpress: {},
};

export const chilexpressReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.chilexpressRegionsSetIsLoading:
			return {
				...state,
				isLoadingChilexpress: action.payload,
			};
		case types.chilexpressRegionsLoadAll:
			return {
				...state,
				allChilexpressRegions: [...action.payload],
			};
		case types.chilexpressCitiesLoadByRegionId:
			return {
				...state,
				citiesByRegionIdChilexpress: [...action.payload],
			};
		case types.chilexpressBranchesLoadByRegionIdAndCityName:
			return {
				...state,
				branchesByRegionIdAndCityNameChilexpress: [...action.payload],
			};
		case types.chilexpressLoadShippingPrice:
			return {
				...state,
				shippingPriceChilexpress: parseInt(action.payload.res),
				serviceDeliveryCodeChilexpress: action.payload.shippingCode,
			};
		case types.chilexpressResetServiceDeliveryCodeChilexpress:
			return {
				...state,
				serviceDeliveryCodeChilexpress: "",
			};
		case types.chilexpressResetCitiesByRegionIdChilexpress:
			return {
				...state,
				citiesByRegionIdChilexpress: [],
			};
		case types.chilexpressResetShippingPrice:
			return {
				...state,
				shippingPriceChilexpress: 0,
			};
		case types.chilexpressGenerateTicket:
			return {
				...state,
				ticketChilexpress: action.payload,
			};
		case types.chilexpressReImpressTicket:
			return {
				...state,
				ticketChilexpress: action.payload,
			};
		case types.chilexpressGetTrackingInfo:
			return {
				...state,
				trackingInfoChilexpress: action.payload,
			};
		default:
			return state;
	}
};
