import { types } from "./../types/types.js";


const initialState = {
	isLoadingProducts: false,
    latestProducts: [],
	allProducts: [],
	product: {},
};

export const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.productsLoadLatest:
			return {
				...state,
				latestProducts: [ ...action.payload ],
			};
		case types.productsSetIsLoading:
			return { 
				...state,
        		isLoadingProducts: action.payload,
			};
		case types.productsLoadAll:
			return { 
				...state,
				allProducts: [ ...action.payload ],
			};
		case types.productsLoadById:
			return { 
				...state,
				product: action.payload,
			};
		default:
			return state;
	}
};