import { types } from "./../types/types.js";


const initialState = {
	isLoadingConditions: false,
	allConditions: [],
};

export const conditionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.conditionsSetIsLoading:
			return { 
				...state,
        		isLoadingConditions: action.payload,
			};
		case types.conditionsLoadAll:
			return { 
				...state,
				allConditions: [ ...action.payload ],
			};
		default:
			return state;
	}
};