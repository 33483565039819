import { types } from "./../types/types.js";

const initialState = {
	isLoadingTransactions: false,
	sales: [],
	buys: [],
	transactionById: {},
	transactionNextBuyOrder: "",
	transactionToCreate: {},
	totalTransactions: 0,
};

export const transactionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.transactionSetIsLoading:
			return {
				...state,
				isLoadingTransactions: action.payload,
			};
		case types.transactionSetTransactionToCreate:
			return {
				...state,
				transactionToCreate: action.payload,
			};
		case types.transactionsLoadByUser:
			// giver: si es true, se buscan las transacciones del usuario que envía, si es false, se buscan las transacciones del usuario que recibe

			return action.giver
				? {
						...state,
						sales: [...action.payload.rows],
						totalTransactions: action.payload.count,
				  }
				: {
						...state,
						buys: [...action.payload.rows],
						totalTransactions: action.payload.count,
				  };
		case types.transactionLoadById:
			return {
				...state,
				transactionById: action.payload,
			};
		case types.transactionLoadNextBuyOrder:
			return {
				...state,
				transactionNextBuyOrder: action.payload,
			};
		case types.transactionResetNextBuyOrder:
			return {
				...state,
				transactionNextBuyOrder: "",
			};
		case types.resetTransactionById:
			return {
				...state,
				transactionById: {},
			};
		case types.transactionCreateTransaction:
			return {
				...state,
				transactionById: action.payload,
			};
		case types.transactionUpdateTransactionStatus:
			return {
				...state,
				transactionById: action.payload,
				sales: [
					...state.sales.filter(
						(sale) =>
							sale.transaction_id !==
							action.payload.transaction_id
					),
					action.payload,
				],
			};
		default:
			return state;
	}
};
