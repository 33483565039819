import { types } from "./../types/types.js";


const initialState = {
	cartProducts: localStorage.getItem("cart_products") 
    ? JSON.parse(localStorage.getItem("cart_products"))
    : [],
	cartPublications: localStorage.getItem("cart_publications") 
    ? JSON.parse(localStorage.getItem("cart_publications"))
    : [],
};

export const cartReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.cartAddProduct:
            let productInCart = state.cartProducts.find(product => product.product_id === action.payload.product_id)
            
            return productInCart ? 
            {
				...state,
				cartProducts: state.cartProducts.map(product => 
                    product.product_id === action.payload.product_id 
                    ? {...product, quantity: product.quantity + 1}
                    : product
                ),
			}
            :
			{
				...state,
				cartProducts: [...state.cartProducts, {...action.payload, quantity: 1}],
			};
		case types.cartAddProductWithQuantity:
			return { 
				...state,
				cartProducts: [action.payload]
			};
		case types.cartRemoveProduct:
			return { 
                ...state,
				cartProducts: state.cartProducts.filter(
                    (product) => product.product_id !== action.payload,
                ),
			};
        case types.cartAddPublication:
			let publicationInCart = state.cartPublications.find(publication => publication.publication_id === action.payload.publication_id)
            
            return publicationInCart ? 
            {
				...state,
				cartPublications: state.cartPublications.map(publication => 
                    publication.publication_id === action.payload.publication_id 
                    ? {...publication, quantity: 1}
                    : publication
                ),
			}
            :
			{
				...state,
				cartPublications: [...state.cartPublications, {...action.payload, quantity: 1}],
			};
		case types.cartRemovePublication:
			return { 
                ...state,
				cartPublications: state.cartPublications.filter(
                    (publication) => publication.publication_id !== action.payload,
                ),
			};
		case types.cartProductsSet:
			return { 
				...state,
				cartProducts: action.payload
			};
		case types.cartPublicationsSet:
			return { 
				...state,
				cartPublications: action.payload
			};
		case types.cartClearCart:
			return {
                ...state,
				cartProducts: [],
				cartPublications: [],
			};
		default:
			return state;
	}
};