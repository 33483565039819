import { types } from "./../types/types.js";


const initialState = {
	isLoadingBooks: false,
    latestBooks: [],
	bookById:null,
};

export const booksReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.booksLoadLatest:
			return {
				...state,
				latestBooks: [ ...action.payload ],
			};
		case types.booksSetIsLoading:
			return {
				...state,
        		isLoadingBooks: action.payload,
			};
		case types.bookLoadById:
			return{
				...state,
				bookById:action.payload,
				isLoadingBooks:false
			};
		default:
			return state;
	}
};