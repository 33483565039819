import { types } from "./../types/types.js";


const initialState = {
	isLoadingAddresses: false,
	addressesByUserId: [],
};

export const addressesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.addressesSetIsLoading:
			return { 
				...state,
        		isLoadingAddresses: action.payload,
			};
		case types.addressesByUserIdLoad:
			return { 
				...state,
				addressesByUserId: [ ...action.payload ],
			};
		case types.addressesAdd:
			return { 
				...state,
				addressesByUserId: [ ...state.addressesByUserId, action.payload ],
			};
		case types.usersUpdateUserDefaultAddress:
			return{
				...state,
				addressesByUserId: action.payload,
			}
		case types.addressesDelete:
			return { 
				...state,
				addressesByUserId: state.addressesByUserId.filter(
					(addresses) => addresses.address_id !== action.payload,
				),
			};
		default:
			return state;
	}
};