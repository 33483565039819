import { types } from "./../types/types.js";


const initialState = {
	isLoadingBankAccounts: false,
	bankAccountsByUserId: [],
};

export const bankAccountsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.bankAccountsSetIsLoading:
			return { 
				...state,
        		isLoadingBankAccounts: action.payload,
			};
		case types.bankAccountsByUserIdLoad:
			return { 
				...state,
				bankAccountsByUserId: [ ...action.payload ],
			};
		case types.bankAccountsAdd:
			return { 
				...state,
				bankAccountsByUserId: [ ...state.bankAccountsByUserId, action.payload ],
			};
		case types.bankAccountsUpdateUserDefaultBankAccount:
			return{
				...state,
				bankAccountsByUserId: action.payload,
			}
		case types.bankAccountsDelete:
			return { 
				...state,
				bankAccountsByUserId: state.bankAccountsByUserId.filter(
					(bank_accounts) => bank_accounts.bank_account_id !== action.payload,
				),
			};
		default:
			return state;
	}
};