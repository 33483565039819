import { types } from "./../types/types.js";


const initialState = {
	isLoadingBanks: false,
	allBanks: [],
};

export const banksReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.banksSetIsLoading:
			return { 
				...state,
        		isLoadingBanks: action.payload,
			};
		case types.banksLoadAll:
			return { 
				...state,
				allBanks: [ ...action.payload ],
			};
		default:
			return state;
	}
};