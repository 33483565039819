const initialState = {
    searchResults: null,
  };
  
  export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD_SEARCH_WITH_PAGINATION":
            return {
              ...state,
              searchResults: {
                ...state.searchResults,
              },
            };
          default:
            return state;
    }
  };
  
