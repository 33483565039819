import React from 'react';

import LogoBook from './../assets/images/simbolo-celeste.svg';

const LoadingFullScreen = () => {
    return (
        <div className="loading-full-screen__main-content">
            <div className="loading-full-screen__img-text-container">
                <img src={LogoBook} alt="Matchbook logo" className='loading-full-screen__img'/>
                <div className="loading-full-screen__img-text-container__text">Cargando...</div>
            </div>
        </div>
    );
};

export default LoadingFullScreen;
