import { types } from "../types/types.js";


const initialState = {
	checkingLogin: false,
	token: null,
	authUserData: {},
	isLoggedIn: false,
	isLoadingRegister: false,
	forgotPassMessages: {},
};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.authLogin:
			return {
				...state,
				checkingLogin: false,
				isLoggedIn: true,
				authUserData: action.payload,
			};
		case types.authLogout:
			return { 
				isLoggedIn: false, 
				checking: false,
				authUserData: [],
			};
		case types.authCheckingLoginStart:
			return {
				...state,
				checkingLogin: true,
			};
		case types.authCheckingLoginFinish:
			return {
				...state,
				checkingLogin: false,
			};
		case types.authIsLoadingRegister:
			return {
				...state,
				isLoadingRegister: action.payload,
			};
		case types.authSetUserDataAuth:
			return {
				...state,
				authUserData: action.payload,
			};
		case types.authRefreshTokenForUpdate:
			return {
				...state,
				authUserData: action.payload,
			};
		case types.authSetForgotPassMessages:
			return {
				...state,
				forgotPassMessages: action.payload,
			};
		default:
			return state;
	}
};