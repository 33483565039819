import { types } from "./../types/types.js";


const initialState = {
	isLoadingBalance: false,
	balanceByUserId: {},
};

export const balancesReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.balanceSetIsLoading:
			return { 
				...state,
        		isLoadingBalance: action.payload,
			};
		case types.balancesByUserIdLoad:
			return { 
				...state,
				balanceByUserId: action.payload,
			};
		case types.balanceUpdate:
			return{
				...state,
				balanceByUserId: action.payload,
			}
		default:
			return state;
	}
};