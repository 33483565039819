import { types } from "./../types/types.js";

const initialState = {
	isLoadingWithdrawRequests: false,
	withdrawRequestsByUserId: [],
	withdrawRequestsWithPagination: [],
	totalWithdrawRequests: 0,
};

export const withdrawRequestsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.withdrawRequestsSetIsLoading:
			return { 
				...state,
        		isLoadingWithdrawRequests: action.payload,
			};
		case types.withdrawRequestsLoadByUser:
			return { 
				...state,
				withdrawRequestsWithPagination: [ ...action.payload.rows ],
				totalWithdrawRequests: action.payload.count,
			};
		case types.withdrawRequestsAdd:
			return { 
				...state,
				withdrawRequestsWithPagination: [ ...state.withdrawRequestsWithPagination, action.payload ].sort((a,b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0)),
				totalWithdrawRequests: action.payload.count,
			};
		default:
			return state;
	}
};