export const types = {
    // AUTH TYPES
    authLogin: "[Auth] Login",
    authLogout: "[Auth] Logout",
    authCheckingLoginStart: "[Auth] Start Checking login state",
    authCheckingLoginFinish: "[Auth] Finish Checking login state",
    authStartLogin: "[Auth] Start login",
    authStartRegister: "[Auth] Start register",
    authIsLoadingRegister: "[Auth] isLoadingRegister",
    authStartTokenRenew: "[Auth] Start token renew",
    authSetUserDataAuth: "[Auth] Set UserData Auth",
    authRefreshTokenForUpdate: "[Auth] Refresh Token For Update",
    authSetForgotPassMessages: "[Auth] Set Forgot Password Messages",

    // PUBLICATIONS TYPES
    publicationsLoadLatest: "[Publications] Load Latest",
    publicationsSetIsLoading: "[Publications] Set IsLoading",
    publicationsSetIsLoadingSearch: "[Publications] Set IsLoadingSearch",
    publicationsLoadWithPagination: "[Publications] Load With Pagination",
    publicationLoadById: "[Publications] Load By Id",
    publicationReloadPublications: "[Publications] Reload Publications",
    publicationUpdate: "[Publications] Update",
    publicationsUpdateImages: "[Publications] Update Images",
    publicationPriceUpdate: "[Publications] Update Price",
    publicationStatusUpdate: "[Publications] Update Satus",
    publicationsOfUserWithoutPagination:
        "[Publications] Load All Publications By User Without Pagination",
    publicationAdd: "[Publications] Add",
    publicationSet: "[Publications] Set List",
    addAnother: "[Publications] Add another publication",
    publicationsOfUser: "[Publications] User publication List ",
    publicationSearch: "[Publications] Search List",
    resetPublicationSearch: "[Publications] Resert Search List",
    publicationsSetStartSearch: "[Publications] Set Start Search",
    publicationDelete: "[Publications] Delete",
    publicationsResetPublicationById: "[Publications] Reset PublicationById",
    publicationsResetPublicationsWithPagination:
        "[Publications] Reset PublicationsWithPagination",
    publicationRemove: "[Publications] Remove publication from publicationList",
    publicationUpdatePublicationId:
        "[Publications] Update publication_id from publicationList",
    publicationsResetPublicationsList: "[Publications] Reset publicationList",
    publicationsResetPublicationEditNumber:
        "[Publications] Reset publication editNumber",
    resetPublicationsOfUser: "[Publications] Reset publication of user",
    publicationCreate: "[Publications] Create publications adding to arrays",

    // BOOKS TYPES
    booksLoadLatest: "[Books] Load Latest",
    booksSetIsLoading: "[Books] Set IsLoading",
    bookLoadById: "[Books] Load By Id",

    // PRODUCTS TYPES
    productsLoadLatest: "[Products] Load Latest",
    productsSetIsLoading: "[Products] Set IsLoading",
    productsLoadAll: "[Products] Load All Products",
    productsLoadById: "[Products] Load By ID Products",

    // CART TYPES
    cartAddProduct: "[Cart] Add Product",
    cartAddProductWithQuantity: "[Cart] Add Product With Quantity",
    cartRemoveProduct: "[Cart] Remove Product",
    cartAddPublication: "[Cart] Add Publication",
    cartRemovePublication: "[Cart] Remove Publication",
    cartRemoveAll: "[Cart] Remove All",
    cartClearCart: "[Cart] Clear Cart",
    cartProductsSet: "[Cart] Set CartProducts",
    cartPublicationsSet: "[Cart] Set CartPublications",

    // MODAL TYPES
    modalSigninSetIsOpen: "[Modal] Set Signin IsOpen",
    modalSignupSetIsOpen: "[Modal] Set Signup IsOpen",

    // CONDITIONS TYPES
    conditionsSetIsLoading: "[Conditions] Set IsLoading",
    conditionsLoadAll: "[Conditions] Load All Conditions",

    // MODAL TYPES
    modalCoverSetIsOpen: "[Modal] Set Cover IsOpen",

    // ADDRESSES TYPES
    addressesSetIsLoading: "[Addresses] Set IsLoading",
    addressesByUserIdLoad: "[Addresses] Load Addresses by UserId",
    addressesAdd: "[Addresses] Add Address",
    usersUpdateUserDefaultAddress: "[Addresses] Update User Default Address",
    addressesDelete: "[Addresses] Delete Address",

    // BANK ACCOUNTS TYPES
    bankAccountsSetIsLoading: "[BankAccounts] Set IsLoading",
    bankAccountsByUserIdLoad: "[BankAccounts] Load Bank Accounts by UserId",
    bankAccountsAdd: "[BankAccounts] Add Bank Account",
    bankAccountsUpdateUserDefaultBankAccount:
        "[BankAccounts] Update User Default Bank Account",
    bankAccountsDelete: "[BankAccounts] Delete Bank Account",

    // BANKS TYPES
    banksSetIsLoading: "[Banks] Set IsLoading",
    banksLoadAll: "[Banks] Load All Banks",

    // ACCOUNT TYPES TYPES
    accountTypesSetIsLoading: "[AccountTypes] Set IsLoading",
    accountTypesLoadAll: "[AccountTypes] Load All AccountTypes",

    // USER TYPES
    usersSetIsLoading: "[Users] Set IsLoading",
    usersLoadById: "[Users] Load By Id",
    usersLoadByUsername: "[Users] Load By Username",
    usersUpdateMyAccountData: "[Users] Update My Account Data",
    usersUpdateProfile: "[Users] Update Profile",
    usersUpdateProfileImage: "[Users] Update Profile Image",
    usersUpdateShippingPreference: "[Users] Update Shipping Preference",
    usersSetErrors: "[Users] Set Errors",
    usersResetErrors: "[Users] Reset Errors",
    usersResetUserById: "[Users] Reset User By Id",

    // CATEGORIES TYPES
    categoriesSetIsLoading: "[Categories] Set IsLoading",
    categoriesLoadAll: "[Categories] Load All Categories",
    categoriesLoadTop: "[Categories] Load Top Categories",
    categoriesSetIsClickCategory: "[Categories] Set Is Click Category",

    // TRANSACTIONS TYPES
    transactionsLoadByUser: "[Transactions] Load Transactions of User",
    transactionSetIsLoading: "[Transactions] Set IsLoading",
    transactionLoadById: "[Transactions] Load By Id",
    transactionCreateTransaction: "[Transactions] Create Transaction",
    transactionLoadNextBuyOrder:
        "[Transactions] Load Next Transaction Buy Order",
    transactionSetTransactionToCreate:
        "[Transactions] Set Transaction To Create",
    transactionUpdateTransactionStatus:
        "[Transactions] Update Transaction Status",
    resetTransactionById: "[Transactions] Reset Transaction By Id",
    transactionResetNextBuyOrder:
        "[Transactions] Reset Transaction Next Buy Order",

    // BALANCES TYPES
    balancesByUserIdLoad: "[Balances] Load Balance of User",
    balanceUpdate: "[Balances] Update",
    balanceSetIsLoading: "[Balances] Set IsLoading",

    // WITHDRAWREQUESTS TYPES
    withdrawRequestsAdd: "[WithdrawRequests] Add WithdrawRequest",
    withdrawRequestsLoadByUser:
        "[WithdrawRequests] Load WithdrawRequests of User",
    withdrawRequestsSetIsLoading: "[WithdrawRequests] Set IsLoading",

    // CHILEXPRESS TYPES
    chilexpressRegionsSetIsLoading: "[Chilexpress] Set IsLoading",
    chilexpressRegionsLoadAll: "[Chilexpress] Load All Chilexpress Regions",
    chilexpressCitiesLoadByRegionId:
        "[Chilexpress] Load Cities by RegionId Chilexpress",
    chilexpressBranchesLoadByRegionIdAndCityName:
        "[Chilexpress] Load Cities by RegionId And CityName Chilexpress",
    chilexpressLoadShippingPrice:
        "[Chilexpress] Load Shipping Price Chilexpress",
    chilexpressResetShippingPrice:
        "[Chilexpress] Reset Shipping Price Chilexpress",
    chilexpressResetServiceDeliveryCodeChilexpress:
        "[Chilexpress] Reset ServiceDeliveryCodeChilexpress",
    chilexpressGenerateTicket: "[Chilexpress] Generate Ticket",
    chilexpressReImpressTicket: "[Chilexpress] Re Impress Ticket",
    chilexpressResetCitiesByRegionIdChilexpress:
        "[Chilexpress] Reset CitiesByRegionIdChilexpress",
    chilexpressGetTrackingInfo: "[Chilexpress] Get Tracking Info",

    // TRANSBANK TYPES
    transbankCreateTransaction: "[Transbank] Create Transaction",
    transbankCommitTransaction: "[Transbank] Commit Transaction",
    transbankSetIsLoading: "[Transbank] Set IsLoading",

    // SHIPPING DETAIL TYPES
    shippingDetailSetIsLoading: "[ShippingDetail] Set IsLoading",
    shippingDetailUpdateSellerAddressId:
        "[ShippingDetail] Update Seller Address Id",
    shippingDetailUpdateTrackingNumber:
        "[ShippingDetail] Update Tracking Number",
    shippingDetailUpdateDeliveryConfirmation:
        "[ShippingDetail] Update Delivery Confirmation",
    shippingDetailSetSellerEmail: "[ShippingDetail] Set Seller Email",

    // EMAIL SENDER TYPES
    emailSenderIsSending: "[EmailSender] Set IsSending",
    emailSenderSetMessage: "[EmailSender] Set Message",
};
