import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "./../reducers/authReducer";
import { publicationsReducer } from "./../reducers/publicationsReducer";
import { booksReducer } from "./../reducers/booksReducer";
import { productsReducer } from "./../reducers/productsReducer";
import { modalsReducer } from "./../reducers/modalsReducer";
import { cartReducer } from "./../reducers/cartReducer";
import { conditionsReducer } from "./../reducers/conditionsReducer";
import { addressesReducer } from "./../reducers/addressesReducer";
import { bankAccountsReducer } from "./../reducers/bankAccountsReducer";
import { banksReducer } from "./../reducers/banksReducer";
import { accountTypesReducer } from "./../reducers/accountTypesReducer";
import { usersReducer } from "./../reducers/usersReducer";
import { categoriesReducer } from "./../reducers/categoriesReducer";
import { transactionsReducer } from "./../reducers/transactionsReducer";
import { withdrawRequestsReducer } from "./../reducers/withdrawRequestsReducer";
import { balancesReducer } from "./../reducers/balancesReducer";
import { chilexpressReducer } from "./../reducers/chilexpressReducer";
import { transbankReducer } from "./../reducers/transbankReducer";
import { shippingDetailsReducer } from "./../reducers/shippingDetailsReducer";
import { searchReducer } from "./../reducers/nav_barReducer";

const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const reducers = combineReducers({
    auth: authReducer,
    publications: publicationsReducer,
    books: booksReducer,
    products: productsReducer,
    modals: modalsReducer,
    cart: cartReducer,
    conditions: conditionsReducer,
    addresses: addressesReducer,
    bank_accounts: bankAccountsReducer,
    banks: banksReducer,
    account_types: accountTypesReducer,
    users: usersReducer,
    categories: categoriesReducer,
    transactions: transactionsReducer,
    withdraw_requests: withdrawRequestsReducer,
    balances: balancesReducer,
    chilexpress: chilexpressReducer,
    transbank: transbankReducer,
    shipping_details: shippingDetailsReducer,
    search: searchReducer,
});

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
);
