import { types } from "./../types/types.js";


const initialState = {
	isLoadingTransbank: false,
	transbankTransactionCreateData: {},
	transbankTransactionCommitData: {},
};

export const transbankReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.transbankSetIsLoading:
			return {
				...state,
				isLoadingTransbank: action.payload,
			};
		case types.transbankCreateTransaction:
			return {
				...state,
				transbankTransactionCreateData: action.payload,
			};
        case types.transbankCommitTransaction:
            return {
                ...state,
                transbankTransactionCommitData: action.payload,
            };
		default:
			return state;
	}
};