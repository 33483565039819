import React, { Suspense, lazy } from "react";

import { Provider } from "react-redux";
import { store } from "./store/store";
import ReactGA from "react-ga";
import LoadingFullScreen from "./components/LoadingFullScreen";
const Navigation = lazy(() => import("./router/Navigation"));

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

export const App = () => {
    return (
        <Provider store={store}>
            <Suspense fallback={<LoadingFullScreen />}>
                <Navigation />
            </Suspense>
        </Provider>
    );
};
