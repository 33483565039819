import { types } from "./../types/types.js";

const initialState = {
	isLoadingShippingDetail: false,
	shippingDetailOnTransaction: [],
	sellerEmail: "",
};

export const shippingDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.shippingDetailSetIsLoading:
			return {
				...state,
				isLoadingShippingDetail: action.payload,
			};
		case (types.shippingDetailUpdateSellerAddressId,
		types.shippingDetailUpdateTrackingNumber,
		types.shippingDetailUpdateDeliveryConfirmation):
			return {
				...state,
				shippingDetailOnTransaction: action.payload,
			};
		case types.shippingDetailSetSellerEmail:
			return {
				...state,
				sellerEmail: action.payload,
			};

		default:
			return state;
	}
};
