import { types } from "./../types/types.js";

const initialState = {
	isLoadingUsers: false,
	userById: {},
	errors: ["no initial errors"],
};

export const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.usersSetIsLoading:
			return {
				...state,
				isLoadingUsers: action.payload,
			};
		case types.usersLoadById:
			return {
				...state,
				userById: action.payload,
			};
		case types.usersLoadByUsername:
			return {
				...state,
				userById: action.payload,
			};
		case types.usersUpdateMyAccountData:
			return {
				...state,
				userById: action.payload,
			};
		case types.usersUpdateProfile:
			return {
				...state,
				userById: action.payload,
			};
			case types.usersResetUserById:
				return {
					...state,
					userById: {},
				};
		case types.usersUpdateProfileImage:
			return {
				...state,
				userById: action.payload,
			};
		case types.usersSetErrors:
			return {
				...state,
				errors: action.payload,
			};
		case types.usersResetErrors:
			return {
				...state,
				errors: ["no initial errors"],
			};
		default:
			return state;
	}
};
