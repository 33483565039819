import { types } from "./../types/types.js";


const initialState = {
	isModalSigninOpen: false,
	isModalSignupOpen: false,
	isModalCoverOpen: false,
};

export const modalsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.modalSigninSetIsOpen:
			return {
				...state,
				isModalSigninOpen: action.payload,
			};
		case types.modalSignupSetIsOpen:
			return {
				...state,
				isModalSignupOpen: action.payload,
			};
			case types.modalCoverSetIsOpen:
			return {
				...state,
				isModalCoverOpen: action.payload,
			};
		default:
			return state;
	}
};